import {
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@mui/material";
import moment, { Moment } from "moment";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import {useMutation, useQuery} from "@apollo/client";
import {ACCESS_TIME_INFO, GET_ACCESS_TIME, SAVE_ACCESS_TIME} from "./AccessTime.gql";
import {
    AccessTimeInput,
    AccessTimeQuery,
    SaveAccessTimeMutation,
    SaveAccessTimeMutationVariables
} from "../../../generated/graphql";
import {filter} from "graphql-anywhere";
import {TimePicker} from "@mui/lab";

type TimePeriod = { active: boolean, from: Moment, to: Moment }
const initTime = {active: false, from: moment(), to: moment()}

const initialValues = {
    "1": {...initTime},
    "2": {...initTime},
    "3": {...initTime},
    "4": {...initTime},
    "5": {...initTime},
    "6": {...initTime},
}

export const AccessTime = () => {

    const [saveAccessTime] = useMutation<SaveAccessTimeMutation, SaveAccessTimeMutationVariables>(SAVE_ACCESS_TIME);
    const { data } = useQuery<AccessTimeQuery>(GET_ACCESS_TIME);

    const formik = useFormik<{ [w: string]: TimePeriod }>({
        initialValues: {
            ...initialValues
        },
        onSubmit: async (values) => {
            try {
                const accessTime: AccessTimeInput[] = [];
                for (const [weekday, item] of Object.entries(values)) {
                    if (item.active) {
                        accessTime.push({from: item.from, to: item.to , weekday: +weekday});
                    }
                }

                await saveAccessTime({variables: {accessTime}});
                alert('Сохранено');
            } catch (e) {
                alert('Ошибка! =(');
            }
        },
    });

    useEffect(() => {
        if(data?.accessTime) {
            formik.setValues(Object.fromEntries(Object.entries(initialValues)
                .map(([w,at]) => {
                    let accessTime = data?.accessTime?.find(a => a.weekday === +w)
                    if(accessTime) {
                        accessTime = filter(ACCESS_TIME_INFO,accessTime)
                    }
                    return [`${w}`, {
                        active: !!accessTime,
                        from: accessTime?.from ? moment(accessTime.from) : initTime.from,
                        to: accessTime?.to ? moment(accessTime.to) : initTime.to
                    }];
                })))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant='h4'>Рабочие часы</Typography>
            <List>
                {moment.localeData().weekdays().slice(1).map((weekday, i) => {
                    const value = i + 1;
                    const labelId = `checkbox-list-label-${value}`;
                    return (

                        <ListItem
                            key={weekday}
                            role={undefined}
                            dense
                            onClick={() => formik.setFieldValue(`[${value}].active`, !formik.values[value].active)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                    checked={formik.values[value].active}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={weekday}/>
                            {formik.values[value].active && (
                                <ListItemSecondaryAction>
                                    <TimePicker
                                        {...formik.getFieldProps(`[${value}].from`)}
                                        onChange={(v) => formik.setFieldValue(`[${value}].from`, v)}
                                        label="C"
                                        ampm={false}
                                        renderInput={(props) => <input name='from' onBlur={() => formik.handleBlur("from")}/>}
                                    />
                                    <TimePicker
                                        {...formik.getFieldProps(`[${value}].to`)}
                                        onChange={(v) => formik.setFieldValue(`[${value}].to`, v)}
                                        label="По"
                                        ampm={false}
                                        renderInput={(props) => <input name='to' onBlur={() => formik.handleBlur("to")}/>}
                                    />
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>
                    )
                })}
            </List>
            <Button size="small" color="primary" type='submit'>Сохранить</Button>
        </form>
    )
}


