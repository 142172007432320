import React, {useCallback, useRef} from "react";
import {
    Button,
    DialogActions, DialogContent, DialogTitle,
    FilledInput,
    FormControl,
    InputAdornment,
    InputLabel,
    TextField
} from "@mui/material";
import {useFormik} from "formik";
import DatePicker from '@mui/lab/DatePicker';

import {useMutation} from "@apollo/client";
import {UPDATE_EVENTS} from "./UpdateLessonForm.gql";
import {
    UpdateEventsMutation, UpdateEventsMutationVariables,
} from "../../generated/graphql";
import moment from "moment";
import {TimePicker} from "@mui/lab";

type FormType = {
    id: number,
    title: string,
    start: string,
    end: string | null,
    duration: number,
    freeTimeDuration: number,
    cost: number,
    learnerName: string,
    lessonName: string,
}

export const UpdateLessonForm = ({initValues, close}:{initValues: FormType, close: () => void}) => {
    const isMultiRef = useRef(false);

    const formik = useFormik<FormType>({
        initialValues: initValues,
        onSubmit: async values => {
            await updateEvents({ variables: {
                event: {
                    title: values.title,
                    start: moment(values.start, 'DD.MM.YYYY HH:mm').toISOString(),
                    freeTimeDuration: values.freeTimeDuration,
                    end: moment(values.end, 'DD.MM.YYYY').toISOString(),
                    cost: values.cost,
                },
                eventId: values.id,
                isMulti: isMultiRef.current
            }})
            close();
        },
    });

    const update = useCallback((isMulti: boolean) => () => {
        isMultiRef.current = isMulti;
        formik.submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [updateEvents, {loading:pending}] = useMutation<UpdateEventsMutation, UpdateEventsMutationVariables>(UPDATE_EVENTS);
    return <>
        <DialogTitle> Редактирование события </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
            <DialogContent>
                <p>
                    <TextField label="Предмет" disabled value={initValues?.lessonName}/>
                </p>
                <p>
                    <TextField label="Ученик" disabled value={initValues?.learnerName}/>
                </p>
                <p>
                    <TextField label="Название занятия" name='title' onChange={formik.handleChange} value={formik.values.title}/>
                </p>
                <p>
                    <div>
                        <InputLabel shrink>Начало занятия</InputLabel>
                        <TimePicker
                            inputFormat='DD.MM.YYYY HH:mm'
                            ampm={false}
                            value={formik.values.start ? moment(formik.values.start, 'DD.MM.YYYY HH:mm') : ''}
                            onChange={(d, str) => formik.setFieldValue("start", str)}
                            renderInput={(props) => <input name='start' onBlur={() => formik.handleBlur("start")}/>}
                        />
                    </div>
                </p>
                <p>
                    <div>
                        <InputLabel shrink>Окончание занятия</InputLabel>
                        <TimePicker
                            inputFormat='DD.MM.YYYY HH:mm'
                            ampm={false}
                            value={formik.values.start ? moment(formik.values.end, 'DD.MM.YYYY HH:mm') : ''}
                            onChange={(d, str) => formik.setFieldValue("end", str)}
                            renderInput={(props) => <input name='end' onBlur={() => formik.handleBlur("end")}/>}
                        />
                    </div>
                </p>
                <p>
                    <div>
                        <InputLabel shrink>Окончание занятия</InputLabel>
                        <DatePicker
                            inputFormat='DD.MM.YYYY'
                            autoFocus
                            value={formik.values.end && moment(formik.values.end, 'DD.MM.YYYY')}
                            onChange={(d, str) => formik.setFieldValue("end", str)}
                            showTodayButton
                            renderInput={(props) => <input name='end' onBlur={() => formik.handleBlur("end")}/>}
                        />
                    </div>
                </p>
                <p>
                    <InputLabel shrink>Длительность перерыва</InputLabel>
                    <FormControl variant="outlined">
                        <FilledInput
                            type="number"
                            value={formik.values.freeTimeDuration}
                            onChange={formik.handleChange}
                            endAdornment={<InputAdornment position="end">минут</InputAdornment>}
                            aria-describedby="filled-free-Time-Duration"
                            name="freeTimeDuration"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                    </FormControl>
                </p>
                <p>
                    <InputLabel shrink>Стоимость занятия</InputLabel>
                    <FormControl variant="outlined">
                        <FilledInput
                            type="number"
                            value={formik.values.cost}
                            onChange={formik.handleChange}
                            endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
                            aria-describedby="filled-cost"
                            name="cost"
                            inputProps={{
                                'aria-label': 'weight',
                            }}
                        />
                    </FormControl>
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} disabled={pending}>
                    Отменить
                </Button>
                <Button onClick={update(true)} color="secondary" disabled={pending}>
                    Изменить все последующие
                </Button>
                <Button autoFocus onClick={update(false)} color="primary" disabled={pending}>
                    Изменить только это занятие
                </Button>
            </DialogActions>
        </form>
    </>;
}
