import makeStyles from '@mui/styles/makeStyles';
import { Container, Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {Link} from "react-router-dom";
import moment from "moment";
import React from "react";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: "white",
            textDecoration: 'underline dashed',
            lineHeight: 2,
            '&:active': {
                color: "red",
            }
        },
        footer: {
            background: 'black', color: 'white',
            padding: theme.spacing(2),
        }
    }),
);

export function Footer() {
    const classes = useStyles();
    return <div className={classes.footer}>
        <Container>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Typography variant="body1">Контакты</Typography>

                    <Typography variant="body2">
                        <p><a target="_blank" href="https://repetitor.ru/view/repetitor-po-russkomu-yazyku-shulgina-kristina-pavlovna12320"
                                                         className={classes.link}>Профиль на REPETiTOR.ru</a></p>
                        <p>По всем техническим вопросам: <a href="mailto:tech@tutor-shulgina.ru"
                                                            className={classes.link}>tech@tutor-shulgina.ru</a></p>
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1">Ученику</Typography>
                    <Typography variant="body2">
                        <Link to="/lk" className={classes.link}>Личный кабинет</Link>
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1">О сервисе</Typography>
                    <Typography variant="body2">
                        <Link to="#policy" className={classes.link}>Политика конфиденциальности</Link>
                    </Typography>
                    <Typography variant="body2">
                        <Link to="#termsofservice" className={classes.link}>Пользовательское
                            соглашение</Link>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    <Typography variant="body2">Copyright © 2020-{moment().format("YYYY")} TUTOR-SHULGINA.RU. Все права
                        защищены.</Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body2">Перепечатка материалов и использование фотографий допускается только с
                        согласия владельцев сайта и при наличии активной ссылки на tutor-shulgina.ru. </Typography>
                    <Typography variant="body2">Информация, размещенная на сайте, носит ознакомительный характер и ни
                        при каких условиях не является публичной офертой, определяемой положениями Статьи 437
                        Гражданского кодекса РФ.</Typography>
                </Grid>
            </Grid>
        </Container>
    </div>;
}
