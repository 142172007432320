import {useQuery} from "@apollo/client";
import {REVIEWS_QUERY} from "./Reviews.gql";
import {ReviewsQuery, ReviewsQueryVariables} from "../../generated/graphql";
import React from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {Container, Grid} from "@mui/material";
import { reviewList } from "./Reviews.mock";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const Reviews = () => {
    const { data, loading } = useQuery<ReviewsQuery,ReviewsQueryVariables>(REVIEWS_QUERY);
    const reviews = data?.reviews ?? reviewList;

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = reviews?.length || 0;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    if(!reviews) {
        return loading ? <div>Загрузка...</div> : null;
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={0} component={Box} px={3} py={5}>
                <Grid item>
                    <Typography variant="h4" component="h1" color='#2196f3'>
                        Отзывы
                    </Typography>
                    <Box color='rgb(70, 76, 92)'>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {reviews?.map((step, index) => (
                                <div key={step.id}>
                                    {Math.abs(activeStep - index) <= 2 ? (
                                        <Box
                                            sx={{
                                                minHeight: 400,
                                                display: 'flex',
                                                maxWidth: 1000,
                                                overflow: 'hidden',
                                                textAlign: 'center',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                            }}
                                            p={2}
                                        >
                                            <Typography fontSize={20} fontWeight='bold' paddingBottom={2}>{step.name}</Typography>
                                            <Typography fontSize={18}>{step.text}</Typography>
                                        </Box>
                                    ) : null}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    Следующий
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Предыдущий
                                </Button>
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
