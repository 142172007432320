import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "moment/locale/ru";

import './App.css';
import { createTheme, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {Home} from "./pages/Home";
import {ApolloProvider} from '@apollo/client';
import {client} from "./core/client";
import {Lk} from "./pages/Lk/Lk";
import {ResetPassword} from "./pages/ResetPassword/ResetPassword";
import {Footer} from "./components/Footer/Footer";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        top: {
            minHeight: 'calc(100vh - 172px)',
            paddingBottom: theme.spacing(2),
        },
    }),
);

const theme = createTheme();

function Content() {
    const classes = useStyles();
    return <div className={classes.top}>
        <Routes>
            <Route path="/lk/*" element={<Lk/>}/>
            <Route path="/reset/:token" element={<ResetPassword/>}/>
            <Route path="/" element={<Home/>}/>
        </Routes>
    </div>;
}

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ApolloProvider client={client}>
                        <Router>
                            <Content/>
                            <Footer/>
                        </Router>
                    </ApolloProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
