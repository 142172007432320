import {
    AppBar,
    Backdrop,
    Box,
    Button,
    Container,
    Fade,
    Grid,
    Modal,
    Theme,
    Toolbar,
    Typography,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import {Login} from "../components/Login/Login";
import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Reviews} from "../components/Reviews/Reviews";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            flexGrow: 1,
        },
        content: {
            flexGrow: 1,
        },
        contentText: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 'calc(100% - 440px)',
            }
        },
        contentPicture: {
            flexGrow: 1,
        },
        avatar: {
            width: '440px',
            maxWidth: 'calc(100vw - 38px)',
            height: '640px',
            background: 'url(/photo1.jpg)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paragraph: {
            textIndent: '25px'
        }
    }),
);
export const Home = () => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        // setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className={classes.root}>
                <AppBar position="static">
                    <Container maxWidth="lg">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                Шульгина Кристина Павловна
                            </Typography>
                            <Button color="inherit" onClick={handleOpen}>Личный кабинет ученика</Button>
                        </Toolbar>
                    </Container>
                </AppBar>
                <div>
                    <Container maxWidth="lg" className={classes.content}>
                        <Grid container spacing={0} component={Box} px={3} py={5}>
                            <Grid item className={classes.contentText}>
                                <Typography variant="h4" component="h1" color='#2196f3'>
                                    Репетитор русского и французского языков
                                </Typography>
                                <Box fontSize={20} lineHeight={2} pb={3} color='rgb(70, 76, 92)'>
                                    <ul>
                                        <li>Подготовка к ОГЭ и ЕГЭ по русскому языку</li>
                                        <li>Русский язык для школьников с 3 класса</li>
                                        <li>Обучение французскому языку учеников младших классов</li>
                                    </ul>
                                </Box>
                                <Typography  variant="h5" component="h2" color='#2196f3'>
                                    Образование
                                </Typography>
                                <Box fontSize={15} lineHeight={2} color='rgb(70, 76, 92)'>
                                    <ul>
                                        <li>Бакалавриат. Московсковский государственный университет (МГУ) им. М.В. Ломоносова, 2013-2018 гг</li>
                                        <li>Профессиональная переподготовка. Московская международная академия (ММА), 2018-2019 гг</li>
                                        <li>Магистратура. Московский педагогический государственный университет (МПГУ), 2020-2022 гг</li>
                                    </ul>
                                </Box>
                                <Typography  variant="h5" component="h2" color='#2196f3'>
                                    Педагогическая деятельность
                                </Typography>
                                <Box fontSize={15} lineHeight={2} color='rgb(70, 76, 92)'>
                                    <ul>
                                        <li>Преподавание русского и французского языков. Сеть образовательных центров "АкадемКлуб", 2017-2020 гг</li>
                                        <li>Работа методистом. Сеть образовательных центров "АкадемКлуб", 2019-2020 гг</li>
                                    </ul>
                                    <Button variant="outlined" size="small" endIcon={<ArrowForwardIcon />}>
                                        Подробнее
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item style={{width: '440px'}}>
                                <div className={classes.avatar}/>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div style={{background: 'rgb(215 232 255)'}}>
                    <Container maxWidth="lg" className={classes.content}>
                        <Grid container spacing={0} component={Box} px={3} py={10}>
                            <Grid item>
                                <Typography variant="h4" component="h1" color='#2196f3'>
                                    Индивидуальный подход
                                </Typography>
                                <Box fontSize={20} lineHeight={3} color='rgb(70, 76, 92)'>
                                    <ul>
                                        <li>Оценка знаний</li>
                                        <li>Опредениение целей обучения</li>
                                        <li>Разработка индивидуальной программы</li>
                                        <li>Подбор обучающих материалов</li>
                                        <li>Подготовка индивидуальных домашних заданий</li>
                                        <li>Проведение проверочных работ и корректировка программы</li>
                                    </ul>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div>
                    <Container maxWidth="lg" className={classes.content}>
                        <Grid container spacing={0} component={Box} px={3} py={10}>
                            <Grid item>
                                <Typography variant="h4" component="h1" color='#2196f3'>
                                    О себе
                                </Typography>
                                <Box fontSize={20} lineHeight={2} color='rgb(96 124 138)'>
                                    <p className={classes.paragraph}>
                                        Мне очень важны успехи моих учеников, поэтому я провожу контроль полученных знаний.
                                        При выявлении пробелов сразу их устраняю. Добиваюсь отличного усвоения материала.
                                        Всегда совершенствую свои навыки: в данный момент учусь в магистратуре института
                                        филологии МПГУ (программа "Русский язык в различных сферах коммуникации", русская
                                        филология), прохожу курсы повышения квалификации, посещаю лекции и мастер-классы по
                                        педагогике и психологии. Все полученные знания сразу же применяю на практике.
                                        Считаю, что хороший преподаватель - это человек, который не просто научит новому,
                                        поможет добиться определенных результатов, но и изменит отношение к предмету,
                                        привьет интерес к науке и выработает привычку всегда стремиться к знаниям.
                                    </p>
                                    <p className={classes.paragraph}>
                                        Верю, что залог успешных занятий - это приятная атмосфера, дружеские отношения,
                                        поддержка ученика в моменты успеха и неудач, умение корректно исправлять ошибки,
                                        усердная работа, которая не воспринимается в тягость.
                                    </p>
                                    <p className={classes.paragraph}>
                                        Делаю свою работу ответственно и качественно. Для меня важно видеть прогресс
                                        ученика после каждого занятия. Составляю индивидуальную программу, подбираю
                                        индивидуальные домашние задания. Доступна в любое время для ученика и родителей
                                        по WhatsApp и Telegram по любым вопросам.
                                    </p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div style={{background: 'rgb(96 125 139)'}}>
                    <Container maxWidth="lg" className={classes.content}>
                        <Grid container spacing={0} component={Box} px={3} py={10}>
                            <Grid item>
                                <Typography  variant="h4" component="h2" color='#d7e8ff'>
                                    Научная деятельность
                                </Typography>
                                <Box fontSize={20} lineHeight={2} color='rgb(255, 255, 255)'>
                                    <ul>
                                        <li>Участие в международной научной конференции "Язык и поэтика текста". МПГУ, 22.01.2021</li>
                                        <li>Участие в международной научной конференции "Научная парадигма современной русистики". МПГУ, 24.09.2021</li>
                                        <li>Участие во всероссийской научной конференции "Лексикология и грамматика русского языка и стилистика художественного текста". МГОУ, 17.12.2021</li>
                                        <li>Участие в международной научной конференции "Язык и поэтика текста". МПГУ, 21.01.2022</li>
                                        <li>Публикация в сборнике научных статей "Актуальные проблемы русистики и поэтики текста", 2022 г</li>
                                        <li>Публикация в международном научном журнале "Молодой учёный" №4 (399) январь 2022 г</li>
                                    </ul>
                                    <Button variant="contained" size="small" endIcon={<ArrowForwardIcon />}>
                                        Документы и сертификаты
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div style={{maxWidth: '100vw', overflow: 'hidden'}}>
                    <Reviews />
                </div>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div>
                        <Login/>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}
