import {ApolloClient, ApolloLink, fromPromise, HttpLink, InMemoryCache} from '@apollo/client';
import {NEW_TOKEN} from "./token.gql";
import {NewTokenMutation, NewTokenMutationVariables} from "../generated/graphql";
import {onError} from "@apollo/client/link/error";

const httpLink = new HttpLink({uri: 'http://localhost:4000/graphql'});

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token');
    const authorization = token ? `Bearer ${token}` : undefined;
    // add the authorization to the headers
    operation.setContext({
        headers: {
            authorization,
        }
    });

    return forward(operation);
})

const errorLink = onError(({
                               graphQLErrors, networkError, operation, forward
                           }) => {
    if (graphQLErrors) {
        for (const error of graphQLErrors) {
            const {message, locations, path} = error;
            if (message === 'Invalid Token') {
                const refreshToken = localStorage.getItem('refreshToken');
                if(!refreshToken) {
                    continue;
                }
                return fromPromise(
                    getNewToken(refreshToken)
                ).filter(value => Boolean(value)).flatMap((token) => {
                    // retry the request, returning the new observable
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: `Bearer ${token}`,
                        },
                    });
                    return forward(operation);
                });
            }
            console.log(`[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
        }
    }
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
});

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return client.resetStore().catch(() => {});
}

async function getNewToken(token: string): Promise<string | null> {
    try {
        const {data} = await client.mutate<NewTokenMutation, NewTokenMutationVariables>({
            mutation: NEW_TOKEN,
            variables: {token}
        });
        if(data?.useRefreshToken?.accessToken) {
            localStorage.setItem('token', data?.useRefreshToken?.accessToken);
        }
        if(data?.useRefreshToken?.refreshToken) {
            localStorage.setItem('refreshToken', data?.useRefreshToken?.refreshToken);
        }
        return data?.useRefreshToken?.accessToken || '';
    } catch (e) {
        await logout();
        return null;
    }
}
